import Button from '@/components/button';
import { FC } from 'react';

import styles from './roomstay-blog-text-footer.module.scss';

export interface BookADemoProps {
    headline?: string;
    subHeadline?: string;
    buttonName?: string;
    buttonLink?: string;
}

const BookADemo: FC<BookADemoProps> = (props) => {
    const { headline, subHeadline, buttonName, buttonLink = '/book-demo' } = props;
    return (
        <div className="container footer-book-a-demo-container">
            <div className="footer-book-a-demo--background-container">
                <svg className="background--curve" width="100%" height="100%" viewBox="0 0 1919 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M-1 50C-1 50 479 0 959 0C1439 0 1919 50 1919 50H-1Z" fill="#7C39FF" />
                </svg>
                <div className="background--main"></div>
            </div>

            <div className="footer-book-a-demo--content">
                {headline ? (
                    <div
                        className={styles.headline}
                        dangerouslySetInnerHTML={{
                            __html: headline || '',
                        }}
                    ></div>
                ) : (
                    <div style={{ paddingTop: '150px' }} className="content--headline-default">
                        Start increasing your direct revenue today by giving your guests the seamless booking <span className="headline--highlight">experience they deserve.</span>
                    </div>
                )}

                {subHeadline && (
                    <div
                        className={styles.subHeadline}
                        dangerouslySetInnerHTML={{
                            __html: subHeadline || '',
                        }}
                    />
                )}
                <Button style={{ marginBottom: '50px' }} type="secondary" size="large" link={buttonLink}>
                    {buttonName || 'Book a demo'}
                </Button>
            </div>
        </div>
    );
};

export default BookADemo;
