import InternalLink from '@/components/InternalLink';
import { ItemProps } from '@/modules/navigation/MenuDropdown';
import { FC } from 'react';

import styles from './sub-navigation-link.module.scss';

interface PopupMenuItemProps {
    menuItem: ItemProps;
    onClick?: () => void;
}

const SubNavigationLink: FC<PopupMenuItemProps> = (props) => {
    const { menuItem, onClick } = props;

    return (
        <div className={styles.wrapperMenuItem}>
            <div className={styles.title}>{menuItem.title}</div>
            {menuItem.childrenItems?.map((item, index) => {
                return (
                    <div key={index}>
                        <InternalLink onClick={onClick} to={item.to} noLine>
                            <div className={styles.menuItemContent}>
                                <div className={styles.wrapperTitle}>
                                    <div className={styles.title}>{item.title}</div>
                                    {menuItem.type === 'complex' ? (
                                        <div className={styles.arrow}>
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="arrow / long_right">
                                                    <path
                                                        id="coolIcon"
                                                        d="M11.3562 8.125L9.74375 9.74375L10.625 10.625L13.75 7.5L10.625 4.375L9.74375 5.25625L11.3562 6.875H1.25V8.125H11.3562Z"
                                                        fill="#763CEF"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                    ) : null}
                                </div>
                                {item.description ? <div className={styles.description}>{item.description}</div> : null}
                            </div>
                        </InternalLink>
                    </div>
                );
            })}
        </div>
    );
};

export default SubNavigationLink;
