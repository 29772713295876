import '@/styles/globals.scss';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

import { NotificationCtxWrapper } from '@/contexts/NotificationContext';
import ThemeContextWrapper from '@/contexts/ThemeContext';
import Layout from '@/layout/Layout';
import { GTM_ID } from '@/shared/env';

function RoomStayApp({ Component, pageProps }: AppProps) {
  const isSingleSite = pageProps?.page?.data?.singleSite;

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />

        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
        <link rel="icon" type="image/png" sizes="192x192" href="/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.webmanifest" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff"></meta>

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=2, minimum-scale=1, user-scalable=yes"
        />
        <title>Increase Direct Bookings with RoomStay | Hotel Reservation System</title>
        <meta
          name="description"
          content="Generate new hotel revenue with RoomStay, an innovative Hotel Booking Engine designed to increase your direct bookings. Schedule a free software demo."
        />
      </Head>

      <Script
        id="ga-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_ID}');
          `,
        }}
      />

      <ThemeContextWrapper>
        <NotificationCtxWrapper>
          <Layout showNavigation={!isSingleSite} showFooter={!isSingleSite}>
            <Component {...pageProps} />
          </Layout>
        </NotificationCtxWrapper>
      </ThemeContextWrapper>
    </>
  );
}

export default RoomStayApp;
