import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef, useEffect, useState } from 'react';

export interface InternalLinkProps {
    to?: string;
    children: string | React.ReactElement;

    noLine?: boolean;

    suffix?: React.ReactNode;
    childrenUrl?: string[];

    extraClasses?: string;
    onClick?: () => void;
}

const InternalLink: React.ForwardRefRenderFunction<HTMLAnchorElement, InternalLinkProps> = (props, ref) => {
    const router = useRouter();
    const [isActive, setIsActive] = useState(false);

    const classes = classNames('link nav-link', {
        [props.extraClasses as string]: !!props.extraClasses,
        '--no-bottom-animation': props.noLine,
        active: isActive,
    });

    useEffect(() => {
        const slug = router.query.slug as any;
        const pageSlug = `/${(Array.isArray(slug) ? slug.join('/') : slug) || router.pathname}`.replace('//', '/');

        if (pageSlug === props.to || props.childrenUrl?.includes(pageSlug)) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [props, router]);

    if (!props.to) {
        return (
            <span
                ref={ref}
                className={classes}
                onClick={(e) => {
                    if (props.onClick) {
                        props.onClick();
                    }
                }}
            >
                <span className="nav-link--animation">{props.children}</span>
                {props.suffix}
            </span>
        );
    }

    return (
        <Link href={props.to}>
            <a
                ref={ref}
                className={classes}
                onClick={(e) => {
                    if (props.onClick) {
                        props.onClick();
                    }
                }}
            >
                <span className="nav-link--animation">{props.children}</span>
                {props.suffix}
            </a>
        </Link>
    );
};

export default forwardRef(InternalLink);
