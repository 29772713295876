import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import InternalLink from '@/components/InternalLink';
import Logo from '@/components/Logo';
import { useThemeContext } from '@/contexts/ThemeContext';
import MenuDropdown from './MenuDropdown';

const Notification = dynamic(() => import('@/components/roomstay-notification/roomstay-notification'));

export interface NavigationProps {}

const Navigation: FC<NavigationProps> = () => {
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
    const [needCloseMobileMenu, setNeedCloseMobileMenu] = useState(false);
    const { width = 0 } = useWindowSize();
    const { articleTheme } = useThemeContext();

    const closeNav = () => {
        setIsMobileMenuActive(false);
    };

    useEffect(() => {
        if (width < 992) {
            if (needCloseMobileMenu) {
                setNeedCloseMobileMenu(false);
                setIsMobileMenuActive(false);
            }
        } else {
            setNeedCloseMobileMenu(true);
            if (isMobileMenuActive) {
                setIsMobileMenuActive(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    return (
        <header className={classNames('header', { 'is-active': isMobileMenuActive })}>
            <Notification />
            <div className="container">
                <div className="background--mobile" />
                <div className="header-content">
                    <Link href={'/'}>
                        <a aria-label="Roomstay Logo" className={classNames('header-content--logo', { 'is-active': isMobileMenuActive })}>
                            <Logo />
                        </a>
                    </Link>
                    <button
                        className={'hamburger hamburger--spin' + (isMobileMenuActive ? ' is-active' : '')}
                        type="button"
                        role="button"
                        aria-label="Menu"
                        onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
                    >
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </button>
                    <div className="header-content--nav">
                        <MenuDropdown
                            isMobile={width < 992}
                            to="/platform"
                            onClick={closeNav}
                            toGuestPortal="/platform/guestportal"
                            childrenItems={[
                                {
                                    title: 'Platform',
                                    type: 'complex',
                                    childrenItems: [
                                        {
                                            title: 'Booking Engine',
                                            description: 'Efficient and converting booking engine designed and built to boost online bookings.',
                                            to: '/platform/booking-engine',
                                        },
                                        {
                                            title: 'RoomRate',
                                            description: 'RoomStay RoomRate product brings live room pricing directly to your website',
                                            to: '/platform/roomrate',
                                        },
                                        {
                                            title: 'RoomFinder',
                                            description: 'RoomFinder is designed and built to be your hotels hook to increase conversion',
                                            to: '/platform/room-finder',
                                        },
                                    ],
                                },
                                {
                                    title: 'Feature',
                                    type: 'simple',
                                    childrenItems: [
                                        {
                                            title: 'Recent Searches',
                                            description: '',
                                            to: '/features/recent-searches',
                                        },

                                        {
                                            title: 'Member Only Deals',
                                            description: '',
                                            to: '/features/member-only-deals',
                                        },

                                        {
                                            title: 'Upselling & Cross-Selling',
                                            description: '',
                                            to: '/features/upselling-cross-selling',
                                        },

                                        {
                                            title: 'Price Bar Search',
                                            description: '',
                                            to: '/features/price-bar-search',
                                        },

                                        {
                                            title: 'Live Pricing',
                                            description: '',
                                            to: '/features/live-pricing',
                                        },
                                    ],
                                },
                                {
                                    title: 'Solutions For',
                                    type: 'complex',
                                    childrenItems: [
                                        {
                                            title: 'Hotels',
                                            description: '',
                                            to: '/solutions/hotels',
                                        },

                                        {
                                            title: 'Holiday Parks',
                                            description: '',
                                            to: '/solutions/holiday-parks',
                                        },
                                        {
                                            title: 'Motels & Apartments',
                                            description: '',
                                            to: '/solutions/motels-apartments',
                                        },
                                    ],
                                },
                            ]}
                        >
                            Platform
                        </MenuDropdown>
                        <InternalLink to="/why-roomstay" onClick={closeNav}>
                            Why RoomStay
                        </InternalLink>
                        <InternalLink to="/pricing" onClick={closeNav}>
                            Pricing
                        </InternalLink>
                        <InternalLink to="/integrations" onClick={closeNav}>
                            Integrations
                        </InternalLink>
                        <InternalLink to="/about-us" onClick={closeNav}>
                            About
                        </InternalLink>
                        <InternalLink to="/blog" onClick={closeNav}>
                            Blog
                        </InternalLink>
                        <InternalLink to="/contact" onClick={closeNav}>
                            Contact Us
                        </InternalLink>

                        <div className="u-marg-left" onClick={closeNav}>
                            <InternalLink noLine to="/book-demo">
                                <span className={classNames('btn', '--primary', { '--secondary': articleTheme })}>Book a Demo</span>
                            </InternalLink>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Navigation;
