import { useThemeContext } from '@/contexts/ThemeContext';
import Footer from '@/modules/footer';
import Navigation from '@/modules/navigation';
import { FC, PropsWithChildren } from 'react';
import LayoutContent from './LayoutContent';
import BookADemo from '@/components/book-a-demo';

interface LayoutProps extends PropsWithChildren {
    showNavigation?: boolean;
    showFooter?: boolean;
}

const Layout: FC<LayoutProps> = (props) => {
    const { showNavigation, showFooter, children } = props;
    const { showBookDemoFooter, singleSite } = useThemeContext();

    return (
        <div className="layout-container">
            {showNavigation && !singleSite ? <Navigation /> : null}

            <LayoutContent>
                {children}
                {showBookDemoFooter ? <BookADemo /> : null}
            </LayoutContent>
            {showFooter && !singleSite ? <Footer /> : null}
        </div>
    );
};

export default Layout;
