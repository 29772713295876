import { FC } from 'react';
import InternalLink from '../InternalLink';
import styles from './guest-portal.module.scss';

interface GuestPortalProps {
    onClick?: () => void;
    to?: string;
}

const GuestPortal: FC<GuestPortalProps> = (props) => {
    const { onClick, to } = props;

    return (
        <div className={styles.container}>
            <InternalLink onClick={onClick} to={to} noLine>
                <div className={styles.guestPortal}>
                    <div className={styles.tag}>New</div>
                    <div className={styles.textGuestPortal}>GuestPortal</div>
                    <div className={styles.textBook}>Book an appointment to learn more</div>
                </div>
            </InternalLink>
        </div>
    );
};

export default GuestPortal;
