import builderBase, { Builder } from '@builder.io/react';
import { GetContentOptions } from '@builder.io/sdk';

import { BUILDER_API_KEY } from '@/shared/env';

interface BaseModelType {
    modelName: string;
}

export interface IFindAll {
    filters?: Partial<{ offset: number; limit: number; extra?: any }>;
    sort?: any;
    includeUnpublished?: boolean;
    limitFields?: string;
    enrich?: boolean;
    fields?: string;
}

export interface IFind {
    params: { url?: string; modelId?: string };
    includeUnpublished?: boolean;
    enrich?: boolean;
    fields?: string;
}

class BaseModel {
    protected builder: Builder;
    protected modelName: string;

    constructor(props: BaseModelType) {
        this.builder = builderBase.init(BUILDER_API_KEY);
        this.modelName = props.modelName;
    }

    findAll = ({
        filters,
        sort,
        includeUnpublished,
        limitFields,
        enrich,
        fields = 'createdBy,createdDate,data,firstPublished,id,lastUpdated,lastUpdatedBy,modelId,name',
    }: IFindAll) => {
        const getConfig: GetContentOptions = {
            query: {},
            options: {
                noTargeting: true,
                includeUnpublished,
                enrich,
            },
            fields,
            omit: 'data.state',
        };

        if (filters?.extra) {
            getConfig.query = filters?.extra;
        }
        if (sort && getConfig.options) {
            getConfig.options.sort = sort;
        }

        if (limitFields) {
            getConfig.fields = limitFields;
        }

        if (filters?.limit) {
            getConfig.limit = filters?.limit;
        }

        if (getConfig.options) {
            if (typeof filters?.offset === 'string') {
                getConfig.options.offset = parseInt(filters?.offset ?? '0');
            } else {
                getConfig.options.offset = filters?.offset;
            }
        }

        return this.builder.getAll(this.modelName, getConfig);
    };

    protected find = async (filter: IFind) => {
        const { params, includeUnpublished = false, enrich, fields = 'createdBy,createdDate,data,firstPublished,id,lastUpdated,lastUpdatedBy,modelId,name' } = filter || {};
        const { url, modelId } = params || {};

        const query: any = {
            userAttributes: {},
            options: {
                includeUnpublished,
                noTargeting: false,
                enrich,
            },
            fields,
            omit: 'data.state',
        };

        if (url) {
            query.userAttributes.urlPath = url;
        }

        if (modelId) {
            query.entry = modelId;
        }

        const data = (await this.builder.get(this.modelName, query).toPromise()) || null;

        return data;
    };

    findByIds = async (ids: string[]) => {
        return this.findAll({
            includeUnpublished: false,
            filters: {
                extra: {
                    id: {
                        $in: ids,
                    },
                },
            },
            enrich: true,
        });
    };

    findNextById = async (id: string, createdDate?: number | null) => {
        const articles = await this.findAll({
            filters: {
                limit: 1,
                offset: 0,
                extra: {
                    createdDate: {
                        $gt: createdDate,
                    },
                    id: {
                        $ne: id,
                    },
                },
            },
            sort: {
                createdDate: 1,
            },
            enrich: true,
        });
        return articles[0] || null;
    };
}

export default BaseModel;
