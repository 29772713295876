import { Builder } from '@builder.io/react';
import { useRouter } from 'next/router';
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from 'react';

import MessageModel from '@/models/Message';
import { Message } from '@/types/message';

interface IContextNotificationProps {
    messages: Message[];
    isShowing: boolean;
    popMessage: () => Message | undefined;
    addMessages: (message: Message) => void;
    setIsShowing: (state: boolean) => void;
}

export const NotificationCtx = createContext({} as IContextNotificationProps);

export const NotificationCtxWrapper: FC<PropsWithChildren> = (props) => {
    const { children } = props;

    const [messages, setMessages] = useState<Message[]>([]);
    const [isShowNoti, setIsShowingNoti] = useState<boolean>(false);
    const [previewMessageId, setPreviewMessageId] = useState<string>();
    const route = useRouter();

    useEffect(() => {
        let messageId = route.query['message_id'];
        if (Array.isArray(messageId)) {
            messageId = messageId[0];
        }
        setPreviewMessageId(messageId);
    }, [route.query]);

    useEffect(() => {
        (async () => {
            let messages;
            if (previewMessageId && Builder.isPreviewing) {
                messages = await MessageModel.getMessage(previewMessageId, true);
                if (messages) {
                    messages = [messages];
                }
            } else {
                messages = await MessageModel.getAllMessages();
            }
            const validMessage = MessageModel.getValidMessage(messages);
            if (validMessage) {
                setMessages([validMessage]);
            }
        })();
    }, [previewMessageId]);

    const addMessages = (message: Message) => {
        setMessages((mess) => {
            return [...mess, message];
        });
    };

    const popMessage = (): Message | undefined => {
        const message = messages.pop();
        setMessages((mess) => {
            mess.pop();
            return mess;
        });
        return message;
    };

    return (
        <NotificationCtx.Provider
            value={{
                messages,
                isShowing: isShowNoti,
                popMessage,
                addMessages,
                setIsShowing: setIsShowingNoti,
            }}
        >
            {children}
        </NotificationCtx.Provider>
    );
};

export const useNotification = () => useContext(NotificationCtx);
