import { useThemeContext } from '@/contexts/ThemeContext';
import BookADemo from '@/components/book-a-demo';
import { FC, PropsWithChildren, memo } from 'react';

interface LayoutContentProps extends PropsWithChildren {}

const LayoutContent: FC<LayoutContentProps> = ({ children }) => {
    const { contentClasses } = useThemeContext();

    return <div className={contentClasses}>{children}</div>;
};

export default memo(LayoutContent);
