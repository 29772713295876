import React, { FC } from 'react';

import styles from './spinner.module.scss';
import classNames from 'classnames';

interface SpinnerProps {
    className?: string;
    size?: number;
    strokeWidth?: number;
    hide?: boolean;
}

const Spinner: FC<SpinnerProps> = ({ className, size, strokeWidth, hide }) => {
    return (
        <div
            style={{
                width: size,
                height: size,
                borderWidth: strokeWidth,
                display: hide ? 'none' : 'flex',
                flexShrink: 0,
            }}
            className={classNames(styles.spinnerLoadMore, className)}
        />
    );
};

export default Spinner;
