import { useNotification } from '@/contexts/NotificationContext';
import { Options } from '@popperjs/core';
import { animated, useSpring } from '@react-spring/web';
import { FC, InsHTMLAttributes, useEffect, useRef, useState } from 'react';
import { Modifier, usePopper } from 'react-popper';
import { useHover } from 'usehooks-ts';

export interface PopoverProps extends Partial<Pick<Options, 'placement' | 'strategy'>>, InsHTMLAttributes<HTMLDivElement> {
    targetRef: Element;
    open?: boolean;
    offset?: [x: number, y: number];
    openOnHover?: boolean;
    onOutsideClick?: () => void;
}

const Popover: FC<PopoverProps> = ({ children, targetRef, open, placement, offset, openOnHover, strategy, onOutsideClick, ...props }) => {
    const isControlled = open !== undefined;
    const ref = useRef<HTMLDivElement>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const isHover = useHover(ref);
    const [show, setShow] = useState<boolean | undefined>(open);
    const { isShowing } = useNotification();

    const style = useSpring({
        maxHeight: show && ref.current ? ref.current.offsetHeight : 0,
    });

    useEffect(() => {
        if (!open && openOnHover) {
            setShow(isHover);
        } else {
            setShow(open);
        }
    }, [open, openOnHover, isHover]);

    const modifiers: Modifier<any>[] = [
        {
            name: 'flip',
            options: {
                fallbackPlacements: ['top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'right', 'left'],
            },
        },
        {
            name: 'preventOverflow',
            options: {
                enabled: true,
                escapeWithReference: true,
                boundariesElement: 'viewport',
            },
        },
    ];
    if (offset) {
        modifiers.push({
            name: 'offset',
            options: {
                offset: offset,
            },
        });
    }

    const { styles, attributes, update } = usePopper(targetRef, popperElement, {
        placement: placement || 'top',
        strategy,
        modifiers,
    });

    useEffect(() => {
        const toggleShow = () => {
            !isControlled && setShow(!show);
        };
        const windowClick = (e: MouseEvent) => {
            if (targetRef && targetRef.contains(e.target as Node)) {
                // Click in target
                !isControlled && toggleShow();
            } else if (popperElement && popperElement.contains(e.target as Node)) {
                // Click in popper element
                setShow(false);
            } else {
                // Click outside
                if (isControlled) {
                    onOutsideClick?.();
                } else setShow(false);
            }
        };
        if (targetRef && update) {
            window.addEventListener('click', windowClick);
        }
        return () => {
            if (targetRef) {
                window.removeEventListener('click', windowClick);
            }
        };
    }, [targetRef, update, show, isControlled, popperElement, onOutsideClick]);

    useEffect(() => {
        setTimeout(() => {
            update?.();
        }, 500);
    }, [isShowing, update]);

    return (
        <div ref={setPopperElement} style={styles.popper} {...attributes.popper} {...props}>
            <animated.div style={{ overflow: 'hidden', ...style }}>
                <div ref={ref}>{children}</div>
            </animated.div>
        </div>
    );
};

export default Popover;
