import { Message } from '@/types/message';
import { BuilderContent } from '@builder.io/sdk';
import BaseModel from './BaseModel';

class MessageModel extends BaseModel {
    constructor() {
        super({ modelName: 'message' });
    }

    getAllMessages = async (includeUnpublished = false, includeDraft = false) => {
        const draft = includeDraft ?? 'draft';
        const messages = await this.builder.getAll(this.modelName, {
            options: { noTargeting: true, includeUnpublished },
            query: {
                published: {
                    $ni: ['archived', draft],
                },
            },
        });
        return messages;
    };

    getMessage = async (id: string, includeUnpublished = false) => {
        return this.find({ params: { modelId: id }, includeUnpublished });
    };

    getValidMessage = (messages: BuilderContent[]): Message | undefined => {
        if (!messages) return;
        const message = messages.find((mess) => {
            const startDate = (mess.data?.startDate ? new Date(mess.data.startDate) : new Date(2000, 1, 1)).getTime();
            const endDate = (mess.data?.endDate ? new Date(mess.data.endDate) : new Date(9999, 1, 1)).getTime();
            // const isRead = localStorage.getItem(mess.id!);
            const now = Date.now();
            const isValid = now > startDate && now < endDate;
            // return isValid && !isRead;
            return isValid;
        });
        if (message && message.data) {
            return { ...(message.data as Message), id: message.id };
        }
        return undefined;
    };
}

export default new MessageModel();
