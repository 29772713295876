import { FC } from 'react';

import { ItemProps } from '@/modules/navigation/MenuDropdown';
import SubNavigationLink from './sub-navigation-link';

import GuestPortal from '../guest-portal/guest-portal';
import styles from './sub-navigation.module.scss';

interface RenderMenuItemProps {
    childrenItems: ItemProps[];
    onClick?: () => void;
    toGuestPortal?: string;
}

const SubNavigation: FC<RenderMenuItemProps> = (props) => {
    const { childrenItems, onClick, toGuestPortal } = props;

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    {childrenItems.map((item, index) => (
                        <div className={styles.wrapperContent} key={item.title}>
                            <SubNavigationLink onClick={onClick} menuItem={item} />
                            {index + 1 !== childrenItems.length && <div className={styles.divider}></div>}
                        </div>
                    ))}
                </div>

                <GuestPortal to={toGuestPortal} />
            </div>
        </>
    );
};

export default SubNavigation;
