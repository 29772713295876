import InternalLink from '@/components/InternalLink';
import Logo from '@/components/Logo';
import dayjs from 'dayjs';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-content">
                    <div className="footer-content--logo">
                        <Logo isFooter />
                    </div>
                    <div className="footer-content--nav">
                        <div className="footer-content--nav-links">
                            <InternalLink to="/platform">Platform</InternalLink>
                            <InternalLink to="/why-roomstay">Why RoomStay</InternalLink>
                            <InternalLink to="/pricing">Pricing</InternalLink>
                            <InternalLink to="/integrations">Integrations</InternalLink>
                            <InternalLink to="/about-us">About</InternalLink>
                            <InternalLink to="/blog">Blog</InternalLink>
                            <InternalLink to="/contact">Contact Us</InternalLink>
                            <InternalLink to="/privacy">Privacy Policy</InternalLink>
                        </div>
                        <div className="footer-content--nav-links">
                            <InternalLink to="/blog/increase-hotel-revenue">Increase Hotel Revenue</InternalLink>
                            <InternalLink to="/blog/increase-direct-bookings">Increase Direct Booking</InternalLink>
                            <InternalLink to="/faq">FAQ</InternalLink>
                        </div>
                        <div className="footer-content--nav-policy">{`Copyright ©${dayjs().year()} Roomstay. All rights reserved.`}</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
