import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import Spinner from '@/components/spinner/spinner';

import { useRouter } from 'next/router';
import styles from './button.module.scss';

export type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export interface ButtonProps extends Omit<HTMLButtonProps, 'type'> {
    link?: string;
    children: ReactNode;
    loading?: boolean;
    type?: 'primary' | 'secondary';
    size?: 'default' | 'large';
    theme?: 'default' | 'primary' | 'blackCurrant';
    htmlType?: HTMLButtonProps['type'];
    hideShadow?: boolean;
}

const Button: FC<ButtonProps> = (props) => {
    const { children, link, className, loading, hideShadow, onClick, type = 'primary', size = 'default', htmlType, theme, ...buttonProps } = props;
    const router = useRouter();

    return (
        <button
            {...buttonProps}
            className={classNames(styles.btn, styles[`btn-${type}`], styles[`btn-${size}`], styles[`btn-theme-${theme}`], className, { [styles.shadowNone]: hideShadow })}
            type={htmlType || 'button'}
            onClick={(e) => {
                if (link) {
                    router.push(link);
                } else onClick?.(e);
            }}
            disabled={loading}
        >
            {loading && <Spinner />}
            <span>{children}</span>
        </button>
    );
};

export default Button;
