import InternalLink from '@/components/InternalLink';
import AutoAutoHeight from '@/components/animations/AutoAutoHeight';
import Popover from '@/components/popover/popover';
import SubNavigation from '@/components/sub-navigation';
import classNames from 'classnames';
import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useHover, useOnClickOutside } from 'usehooks-ts';

export interface ItemProps {
    title: string;
    to?: string;
    description?: string;
    type?: 'complex' | 'simple';
    onClick?: () => void;
    childrenItems?: ItemProps[];
}

interface MenuDropdownProps extends Omit<ItemProps, 'title'> {
    isMobile?: boolean;
    children: string | React.ReactElement;
    childrenItems: ItemProps[];
    toGuestPortal?: string;
}

const MenuDropdown: FC<MenuDropdownProps> = (props) => {
    const { to, onClick, childrenItems, isMobile, children, toGuestPortal } = props;
    const parentRef = useRef<HTMLAnchorElement>(null);
    const isHover = useHover(parentRef);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const childrenUrl = childrenItems.map((item) => item.childrenItems?.map((child) => child.to)).flat();

    useOnClickOutside(parentRef, () => {
        setIsMenuOpen(false);
    });

    useEffect(() => {
        setIsMenuOpen(isHover);
    }, [isHover]);

    const _onMenuClick = () => {
        setIsMenuOpen((val) => !val);
        onClick?.();
    };

    const toggleMobileOpen = (e: MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setIsMenuOpen(false);
        setIsMobileOpen((val) => !val);
    };

    const _renderMenu = useCallback(() => {
        if (!Array.isArray(childrenItems) || !childrenItems.length) return null;
        if (!parentRef.current) return null;

        if (isMobile) {
            return <AutoAutoHeight open={isMobileOpen}>{<SubNavigation onClick={onClick} childrenItems={childrenItems} />}</AutoAutoHeight>;
        }

        return (
            <Popover className="nav-menu-wrapper" targetRef={parentRef.current} placement="bottom-start" open={isMenuOpen} offset={[0, 0]} openOnHover strategy="absolute">
                <SubNavigation childrenItems={childrenItems} toGuestPortal={toGuestPortal} />
            </Popover>
        );
    }, [childrenItems, isMenuOpen, isMobile, isMobileOpen]);

    return (
        <>
            <InternalLink
                ref={parentRef}
                to={to || ''}
                onClick={_onMenuClick}
                childrenUrl={childrenUrl as []}
                suffix={
                    isMobile && (
                        <span className={classNames('nav-arrow-icon', { open: isMobileOpen })} onClick={toggleMobileOpen}>
                            <span className="nav--left-bar"></span>
                            <span className="nav--right-bar"></span>
                        </span>
                    )
                }
            >
                {children}
            </InternalLink>

            {_renderMenu()}
        </>
    );
};

export default MenuDropdown;
