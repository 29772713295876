import useMeasure from '@/hooks/useMeasure';
import { animated, useSpring } from '@react-spring/web';
import { FC, useEffect, useState } from 'react';

interface AutoAutoHeightProps {
    children: React.ReactNode;
    open: boolean;
    startOpen?: boolean;
    onComponentHidden?: () => void;
}

const AutoAutoHeight: FC<AutoAutoHeightProps> = (props) => {
    const [hideComponent, setHideComponent] = useState(!props.open);
    const [animationAtRest, setAnimationAtRest] = useState(false);

    const [loadedOnce, setLoadedOnce] = useState(false);

    const [contentHeight, setContentHeight] = useState(0);
    const [ref, { height }] = useMeasure<HTMLDivElement>();

    const expand = useSpring({
        config: { friction: 25, duration: props.startOpen && !loadedOnce ? 0 : undefined },
        from: {
            height: 0,
        },
        to: {
            height: props.open ? contentHeight : 0,
        },
        onStart: () => setAnimationAtRest(false),
        onRest: () => setAnimationAtRest(true),
    });

    useEffect(() => {
        const setHeight = () => {
            setContentHeight(height);
        };

        setHeight();

        window.addEventListener('resize', setHeight);

        return window.removeEventListener('resize', setHeight);
    }, [height]);

    useEffect(() => {
        if (animationAtRest) {
            setLoadedOnce(true);
        }
    }, [animationAtRest]);

    useEffect(() => {
        if (props.open) {
            setHideComponent(false);
        } else {
            if (animationAtRest) {
                setHideComponent(true);
                props.onComponentHidden?.();
            }
        }
    }, [props.open, animationAtRest]);

    return (
        <animated.span style={{ display: 'block', overflow: animationAtRest ? 'visible' : 'hidden', ...expand }}>
            <div ref={ref}>{!hideComponent && props.children}</div>
        </animated.span>
    );
};

export default AutoAutoHeight;
