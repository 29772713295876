import { ArticleTheme } from '@/types/article.d';
import classNames from 'classnames';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

export enum ThemeStyles {
    Dark = 'dark',
    Light = 'light',
    Primary = 'primary',
    Blog = 'blog',
    BlogWithOutFooter = 'blog',
}
interface ThemeContextType {
    showBookDemoFooter?: boolean;

    contentClasses?: string;
    themeStyle: ThemeStyles | null;

    setThemeStyle: (action: ThemeStyles | null) => void;

    articleTheme: ArticleTheme | null;

    setArticleTheme: (action: ArticleTheme | null) => void;
    setShowBookDemoFooter: Dispatch<SetStateAction<boolean>>;

    /**
     * Workaround for development environment. On production, the single site is handled on `_app.tsx`
     * Don't use outside `/pages/builder-review/[...slug].tsx`
     */
    singleSite?: boolean;
    /**
     * Workaround for development environment. On production, the single site is handled on `_app.tsx`
     * Don't use outside `/pages/builder-review/[...slug].tsx`
     */
    setSingleSite: Dispatch<SetStateAction<boolean>>;
}

interface ThemeContextWrapperProps {
    children: any;
}

export const ThemeContext: React.Context<ThemeContextType> = createContext<ThemeContextType | any>({});

export default function ThemeContextWrapper(props: ThemeContextWrapperProps) {
    const [theme, setTheme] = useState<ThemeStyles | null>(ThemeStyles.Dark);
    const [articleTheme, setArticleTheme] = useState<ArticleTheme | null>(null);
    const [showBookDemoFooter, setShowBookDemoFooter] = useState(false);
    const [singleSite, setSingleSite] = useState(false);

    const [contentClasses, setContentClasses] = useState<string>();

    useEffect(() => {
        switch (theme) {
            case ThemeStyles.Blog:
                setContentClasses('blog-content');
                setShowBookDemoFooter(true);
                break;
            case ThemeStyles.BlogWithOutFooter:
                setContentClasses('blog-content');
                setShowBookDemoFooter(false);
                break;
            case ThemeStyles.Primary:
            case ThemeStyles.Light:
            case ThemeStyles.Dark:
            default:
                setContentClasses('');
                setShowBookDemoFooter(false);
        }
    }, [theme]);

    const workingContext: ThemeContextType = {
        showBookDemoFooter,
        singleSite,

        contentClasses,
        themeStyle: theme,
        articleTheme,

        setThemeStyle: setTheme,
        setArticleTheme,
        setShowBookDemoFooter,
        setSingleSite,
    };

    const classes = classNames('roomstay', {
        [`--theme-${theme}`]: true,
        [`--article-theme-${articleTheme}`]: !!articleTheme,
    });

    return (
        <ThemeContext.Provider value={workingContext}>
            <div className={classes}>{props.children}</div>
        </ThemeContext.Provider>
    );
}

export const useThemeContext = () => useContext(ThemeContext);
